import React, { useState } from "react";
import CoffeeRec from "./CoffeeRec";
import coffee from './coffee';
import { Container, grid } from "@mui/system";
import { Grid, Box, Menu } from "@mui/material";
import { propTypes } from "react-bootstrap/esm/Image";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

const CoffeeRecs = () => {

    const handleChange = (e) => {
        showByState(e.target.value);
    };

    const [state, showByState] = useState("all")
    return (
        <Grid container spacing={2}
            direction="column"
        >
            <Box sx={{ margin: 2, marginTop: 6 }}>
                <FormControl>
                    <InputLabel>State</InputLabel>
                    <Select
                        value={state}
                        label="State"
                        onChange={handleChange}
                    >
                        <MenuItem value={"all"}>All States</MenuItem>
                        <MenuItem value={"AZ"}>Arizona</MenuItem>
                        <MenuItem value={"CA"}>California</MenuItem>
                    </Select>
                </FormControl>
            </Box>

            {coffee.map(rec => (rec.state === state || state === "all") && <Grid item> <CoffeeRec 
                name={rec.name}
                address={rec.address}
                city={rec.city}
                state={rec.state}
                description={rec.description}
                img={rec.img}
            />
            </Grid>

            )}
        </Grid>
    )
}

export default CoffeeRecs;