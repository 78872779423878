import React from 'react';
import Home from './Home';
import './App.css';
import { Container} from '@mui/system';
import { Grid } from '@mui/material';
import { BrowserRouter, Routes, Route} from 'react-router-dom';
import FoodRecs from './FoodRecs';
import NavBar from './NavBar';
import FinalFantasy from './FinalFantasy';
import Places from './Places';
import Links from './Links';

function App() {


  return (
    <Container className="App">
      <BrowserRouter>
        <Grid>
            <div>
              <NavBar />
            </div>          
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route exact path="/places" element={<Places />} />
              <Route exact path="/ffxiv" element={<FinalFantasy />} />
              <Route exact path="links" element={<Links />} />
            </Routes>
        </Grid>
      </BrowserRouter>
    </Container>
  );
}

export default App;
