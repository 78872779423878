import React, {useState} from 'react';
import {Tabs, Tab, Paper, Box, Typography} from '@mui/material';
import FoodRecs from './FoodRecs';
import CoffeeRecs from './CoffeeRecs';
import BarRecs from './BarRecs';
import FunRecs from './FunRecs';

const Places = () => {
    const [value, setValue] = useState(0);

    const handleChange = (e, newValue) => {
        setValue(newValue);
    };
    const allyProps = index => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <Box>
            <Typography variant="h2">
                Steakdriven's Travel Guide
            </Typography>
            <Typography paragraph={true}>
                As an adventurer with discerning tastes, I always make it a point to find out the best places to eat and drink wherever the road takes me.  The following is a list of bars, restaurants, and coffee houses that I would highly recommend trying out at least once.  Since you probably found this website thanks to my networking at a San Francisco Bay Area convention, this segment currently focuses on that area but in the future I plan to add more and more places!
            </Typography>
            <Paper elevation={2}>
                <Tabs onChange={handleChange} value={value}>
                    <Tab label="Food" {...allyProps(0)} />
                    <Tab label="Coffee" {...allyProps(1)}/>
                    <Tab label="Bars" {...allyProps(2)} />
                    <Tab label="Entertainment" {...allyProps(3)} />
                </Tabs>
            </Paper>
            <Box>
                {value === 0 && <FoodRecs />}
                {value == 1 && <CoffeeRecs />}
                {value == 2 && <BarRecs /> }
                {value === 3 && <FunRecs /> }
            </Box>
        </Box>
     
        
    )
}

export default Places;