const recs = [
    {
        name: "50,000 Silver Dollar Inn",
        address: "Exit 16, I-90",
        city: "Haugan",
        state: "MT",
        type: "American",
        description: "Get the Silver Dollar Burger. Yeah. I'm opening with the what-to-get rather than the description.  This place isn't just a restaurant, it's also a comfortable roadside hotel with a bar and gift shop - perfect for a cross country adventure!",
        img: "https://www.onlyinyourstate.com/wp-content/uploads/2018/12/296257_246249685422733_516625560_n.jpg"
    }, 
    {
        name: "Big Texan Ranch Steakhouse",
        address: "7701 I-40 East",
        city: "Amarillo",
        state: "TX",
        type: "American",
        description: "This restaurant gets a lot of hype and it deserves every word. Big juicy steak and filling breakfasts await you",
        img: "https://assets3.thrillist.com/v1/image/2970323/1200x630"
    }, 
    {
        name: "City Diner",
        address: "2951 Monterey Rd",
        city: "San Jose",
        state: "CA",
        type: "American",
        description: "Eclectically decorated spot for classic American breakfast, lunch, and dinner.",
        img: "https://hulafrogimages2.s3.us-east-2.amazonaws.com/5ce75f37293f2.jpg"
    }, 
    {
        name: "Clover Bakery",
        address: "4342 Moorpark Ave",
        city: "San Jose",
        state: "CA",
        type: "Bakery",
        description: "Do you like Japanese Bread? Then the Clover Bakery is the one for you! Try their blueberry danishes and yakisoba bread.  It's right by the Daiso and Kinokuniya in San Jose's lovely Strawberry Plaza.",
        img: "https://fastly.4sqi.net/img/general/width960/7748_ena96EVaCpWMpCNhRUj-HP2fQq-Gih6ZzhhBBw76L0w.jpg"

    },
    {
        name: "Falafel Drive In",
        address: "2301 Stevens Creek Blvd",
        city: "San Jose",
        state: "CA",
        type: "Mediterranean",
        description: "A longstanding institution, the Falafel Drive In offers a delectable spread of expertly prepared falafels, meat wraps, and tantalizing milkshakes.",
        img:"https://cdn.kqed.org/wp-content/uploads/sites/24/2016/08/FalaffelsPita.jpg"
    },
    {
        name: "Five Spot Chivas Grill",
        address: "869 S 1st St",
        city: "San Jose",
        state: "CA",
        type: "Mexican",
        description: "Best tacos in the South Bay if you ask me, but they also have solid breakfast burritos and other Mexican dishes to enjoy. Try their Al Pastor Tacos!",
        img:"https://cdn.usarestaurants.info/assets/uploads/dee40acc821eb05f89c5250035906bae_-united-states-california-santa-clara-county-san-jose-979567-5-spot-chivas-grillhtm.jpg"
    }, 
    {
        name: "Henry's Hi-Life",
        address: "301 W St John St",
        city: "San Jose",
        state: "CA",
        type: "American",
        description: "Established in 1960, Henry's has been providing sumptuous steaks ever since.",
        img: "https://fastly.4sqi.net/img/general/width960/JVeB2PQxH-_h_ojUZh1LoFQQfYx3oRZ1x0dZPsy-3wI.jpg"
    },
    {
        name: "Rock Bottom Brewery",
        address: "1875 S Bascom Ave #700",
        city: "Campbell",
        state: "CA",
        type: "American",
        description: "Restaurant and brewery, I can only speak to the quality of their steak (it's good) and their selection of beer (if they still have Raccoon Red, I'd recommend giving it a go).",
        img: "https://jaymoylovesfood.files.wordpress.com/2018/07/img_9921.jpg"
    },
    {
        name: "St. John's Bar and Grill",
        address: "510 Lawrence Expy",
        city: "Sunnyvale",
        state: "CA",
        type: "American",
        description: "John's is a very hearth and home mom and pop type burger place with a great selection of burgers and beers.",
        img: "https://media-cdn.tripadvisor.com/media/photo-s/06/c7/73/a7/st-john-s-bar-grill.jpg"
    },
    {
        name: "Tasty Burger",
        address: "145 Dartmouth St",
        city: "Boston",
        state: "MA",
        type: "American",
        description:"No-nonsense hole in the wall offering thick and hearty burgers, cold beers, and salty fries.",
        img: "https://m.goingout.com/venue_data/boston/1680/57543849ea0081.50700175-vpp_large.jpg"
    },
    {
        name: "Tony Di Maggio's Pizza",
        address: "3852 Monterey Road",
        city: "San Jose",
        state: "CA",
        type: "Italian",
        description: "Family owned and operated no-frills Italian joint, just a short hop from the drive-in theater. Try the stromboli but fair warning: they're BIG!!!!!!!",
        img: "https://cdn.vox-cdn.com/thumbor/mfALo1M_7E90EdgiPwAwQiY6nQY=/1400x1400/filters:format(jpeg)/cdn.vox-cdn.com/uploads/chorus_asset/file/13662801/12967988_618469134972286_3600062093157475376_o.jpg"
    },
   {
        name: "Zelda's of Capitola",
        address: "203 Esplanade",
        city: "Capitola",
        state: "CA",
        type: "American",
        description: "Some of the best cinnamon rolls I have ever had the decadent pleasure of tasting, this seaside delight is open relatively late.",
        img: "https://media-cdn.tripadvisor.com/media/photo-s/02/5c/f2/61/zelda-s-restaurant-street.jpg"
    },
];

export default recs;
/**
 * Template
 *   
 * {
        name: "",
        address: "",
        city: "",
        state: "",
        type: "",
        description: "",
        img: ""
    }, 
 */