const bars = [
    {
        name: "Branham Lounge",
        address: "1116 Branham Ln",
        city: "San Jose",
        state: "CA",
        description: "Cozy couches and generiously portioned drinks - this lounge is also conveniently located near several places to grab a quick bite.",
        img: "https://www.metrosiliconvalley.com/wp-content/uploads/sites/16/2011/11/branham_lounge.jpg"
    },
    {
        name: "Haberdasher",
        address: "43 W. San Salvador",
        city: "San Jose",
        state: "CA",
        description: "Ever want to have a drink in an underground speakeasy? Well-crafted drinks and a quiet atmosphere await!",
        img: "https://www.haberdashersj.com/franquias/2/56486/editor-html/1404955.jpg"
    },
    {
        name: "Miniboss",
        address: "",
        city: "San Jose",
        state: "CA",
        description: "It's a bar. It's an arcade. How could you go wrong?  Rideshare is your best bet, as parking is limited",
        img: "https://images.radio.com/aiu-media/51077031-10218642723876299-8526957409229340672-n-dcd7e3d5-891d-43ae-8221-d06b0252028c.jpg?width=800"
    },
    {
        name: "Trad'r Sam",
        address: "6150 Geary Blvd",
        city: "San Francisco",
        state: "CA",
        description: "Great prices and strong drinks are always a winning combination.  This tiki bar is located in the Outer Richmond district, close to Golden Gate Park and Ocean Beach. It's also right across the street from Cafe Enchante!",
        img: "https://blog.tebeau.com/wp-content/uploads/2016/02/Tradr-Sams-sign.jpg"
    },
]

export default bars;

/**
 *  {
        name: "",
        address: "",
        city: "",
        state: "",
        description: "",
        img: ""
    },
 */