import React from 'react';
import {Box, Paper, Typography, Grid, Avatar} from '@mui/material';
import Boone from './images/coolswordpose.png'
import LowPose from './images/lowpose.png'
import StarPose from './images/starpose.png'

const FinalFantasy = props => {
    return (
        <Grid container direction='column' alignItems='center'>

        <Grid item>
                <Typography variant="h2">
                    Final Fantasy XIV
                </Typography>
        </Grid>
            
            <Grid item>
                <Typography variant="p">
                    Steak is an avid player of Final Fantasy XIV, he loves to raid and has even made content non Youtube relatd to the game.  He mainly plays on the Cactuar server, Aether data center, but thanks to data center travel he can be anywhere at any time!
                    He is always happy to run new players through the Binding Coils of Bahamut unsynced and will joyfully join up with a group doing the Crystal Tower for the first time.
                </Typography>
            </Grid>
        <Grid item>
            <Typography variant="h3">
                    Meet Boone
                </Typography>
        </Grid>

            <Grid item>
                   <Grid container direction="row" columnSpacing={3} alignItems="center">
                        <Grid item>
                            <Avatar variant={"circular"} alt="The image" src={Boone} style={{
                                width: 300,
                                height: 300,
                            }} />
                        </Grid>
                        <Grid item>
                                <Avatar variant={"circular"} alt="The image" src={LowPose} style={{
                                    width: 300,
                                    height: 300,
                                }} />
                        </Grid>
                        <Grid item>
                                <Avatar variant={"circular"} alt="The image" src={StarPose} style={{
                                    width: 300,
                                    height: 300,
                                }} />
                        </Grid>
                    </Grid>
            </Grid>
            
            <br />
            <Typography variant="p">
                Steak's main character - a classic Final Fantasy fighter, somewhat of a sword-obsessed himbo.  
            </Typography>
        </Grid>
    )
}

export default FinalFantasy;