import React from 'react';
import {Grid, Typography, Box} from '@mui/material';
import {NavLink} from 'react-router-dom';

const inactiveLinkStyle = {textDecoration: 'none', color: 'lime', padding: '1rem'}
const activeLinkStyle = {textDecoration: 'underline', color: 'lime', padding: '1rem'}

const NavBar = (props) => {
    return (
        <Box sx={{backgroundColor:"black", flexGrow:1, padding: '1rem'}}>
            <Grid container spacing={2} alignItems='center' justifyContent='center'>
                <Grid item xs={2}>
                    <Typography variant='h6'>
                        <NavLink style={({isActive}) => isActive ? activeLinkStyle : inactiveLinkStyle} to="/"> Home</NavLink>
                    </Typography>
                </Grid> 
                <Grid item xs={2}>
                    <Typography variant='h6'>
                        <NavLink style={({ isActive }) => isActive ? activeLinkStyle : inactiveLinkStyle} to="/places">Places</NavLink>
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography variant='h6'>
                        <NavLink style={({ isActive }) => isActive ? activeLinkStyle : inactiveLinkStyle} to="/ffxiv">FFXIV</NavLink>
                    </Typography>
                </Grid>          
                <Grid item xs={2}>
                    <Typography variant='h6'>
                        <NavLink style={({ isActive }) => isActive ? activeLinkStyle : inactiveLinkStyle} to="/links">Links</NavLink>
                    </Typography>
                </Grid>                  
        </Grid>
        </Box>

    )
}

export default NavBar;