import React from 'react';
import { Card, Paper, Typography, Grid, Box } from '@mui/material'

const FunRec = props => {
    const { name, address, city, state, description, img } = props;
    return (
        <Paper elevation={6}>
            <Grid container direction="row" justifyContent="space-between" columns={2} wrap alignItems="center">
                <Grid item >
                    <Box sx={{ marginLeft: 4 }}>
                        <Typography variant="h4" align="left">{name}</Typography>
                        <Typography align="left"><strong>{'Address: '}</strong>{address}{' '}{city} , {state} </Typography>
                        <Typography align="left" paragraph>{description}</Typography>
                    </Box>

                </Grid>
                <Grid item>
                    <Box component="img"
                        sx={{
                            height: 233,
                            width: 550,
                            margin: 2,
                            maxHeight: { xs: 233, md: 267 },
                            maxWidth: { xs: 350, md: 350 },
                        }}
                        src={img}
                    />
                </Grid>
            </Grid>
        </Paper>
    )
}

export default FunRec;