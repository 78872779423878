import React from 'react';
import { Paper, Typography, Grid, Card, Box} from '@mui/material';
import Twitter from "./images/twitter.png"

const Links = () => {

    const linkPaperStyle = { maxWidth: "20rem", display: "flex", borderRadius: "50px", margin: ".5rem", alignItems:"center", justifyContent:"center"};
    const linkStyle = {fontWeight: "800"}
    return (
        <Paper>
            <Typography variannt="body1" align="left">
                Below you'll find links to all of the socials that I care to share; please be sure to follow me on Twitch and subscribe on YouTube!
            </Typography>
            <Grid container direction="column" alignItems="center">
                <Grid item>
                    <Paper elevation={12} sx={linkPaperStyle}>
                        <Box component="a" 
                             href="https://www.youtube.com/channel/UC1Oi9xWHUjXyyolvBVUrfbw"
                            sx={{ padding: "5rem", textDecoration: "none", color: "black", fontWeight: "bold", fontSize: "2rem", padding: "2rem" }}
                             
                             >
                        <Grid container direction="row" alignItems={"center"}>       
                            <Box component="img"
                                src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/09/YouTube_full-color_icon_%282017%29.svg/2560px-YouTube_full-color_icon_%282017%29.svg.png"
                                sx={{
                                    height: 100,
                                    width: 100,
                                    maxHeight: { xs: 233, md: 167 },
                                    maxWidth: { xs: 350, md: 250 },
                                }}
                        />
                            <Typography variation="h3" sx={linkStyle} > Youtube</Typography>
                            </Grid>
                   
                        </Box>  
                    </Paper>
             
                </Grid>

                <Grid item>
                    <Paper elevation={12} sx={linkPaperStyle}>
                         <Box component="a" href="https://www.twitch.tv/steakdriven"
                        sx={{ padding:"5rem", textDecoration: "none", color: "black", fontWeight: "bold", fontSize: "2rem", padding: "2rem"}}
                    >
                        <Grid container direction="row" alignItems={"center"}>
                            <Box component="img"
                                src="https://cdn.pixabay.com/photo/2021/12/10/16/38/twitch-6860918_960_720.png"
                                                    sx={{
                                                        height: 100,
                                                        width: 100,
                                                        maxHeight: { xs: 233, md: 167 },
                                                        maxWidth: { xs: 350, md: 250 },
                                                    }}
                                                />
                                <Typography variation="h3" sx={linkStyle} >Twitch</Typography>
                        </Grid>
                   
                    </Box>
                    </Paper>
                   
                </Grid>

                <Grid item>
                    <Paper elevation={12} sx={linkPaperStyle}>
                        <Box component="a" href="https://twitter.com/steakdriven"
                            sx={{ padding: "5rem", textDecoration: "none", color: "black", fontWeight: "bold", fontSize: "2rem", padding: "2rem" }}
                        >
                            <Grid container direction="row" alignItems={"center"}>
                                <Box component="img"
                                    src={Twitter}
                                    sx={{
                                        height: 100,
                                        width: 100,
                                        maxHeight: { xs: 233, md: 167 },
                                        maxWidth: { xs: 350, md: 250 },
                                    }}
                                />
                                <Typography variation="h3" sx={linkStyle} >Twitter</Typography>
                            </Grid>

                        </Box>
                    </Paper>

                </Grid>

            </Grid>
        </Paper>
    )
}

export default Links;