import React, {useState} from "react";
import FoodRec from "./FoodRec";
import recs from './recs';
import { Container, grid } from "@mui/system";
import { Grid, Box, Menu} from "@mui/material";
import { propTypes } from "react-bootstrap/esm/Image";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

const FoodRecs = () => {

    const handleChange = (e) => {
        showByState(e.target.value);
    };

    const [state, showByState] = useState("all")
    return (
                <Grid container spacing={2} 
                direction="column" 
                >
                    <Box sx={{margin: 2, marginTop:6}}>
                        <FormControl>
                        <InputLabel>State</InputLabel>
                        <Select
                            value={state}
                            label="State"
                            onChange={handleChange}
                        >
                            <MenuItem value={"all"}>All States</MenuItem>
                            <MenuItem value={"CA"}>California</MenuItem>
                            <MenuItem value={"MA"}>Massachusetts</MenuItem>
                            <MenuItem value={"MT"}>Montana</MenuItem>
                            <MenuItem value={"TX"}>Texas</MenuItem>
                        </Select>
                    </FormControl>
                    </Box>
                    
                    {recs.map( rec =>  (rec.state === state || state === "all") && <Grid item> <FoodRec name={rec.name} 
                                                           address={rec.address} 
                                                           city={rec.city} 
                                                           state={rec.state} 
                                                           type={rec.type} 
                                                           description={rec.description}
                                                           img={rec.img}
                                                           /> 
                                        </Grid>
                                                           
                                                           )}
                </Grid>
           )
}

export default FoodRecs;