import React, { useState } from "react";
import FunRec from "./FunRec";
import entertainment from './entertainment';
import { Container, grid } from "@mui/system";
import { Grid, Box, Menu } from "@mui/material";
import { propTypes } from "react-bootstrap/esm/Image";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

const FunRecs = () => {

    const handleChange = (e) => {
        showByState(e.target.value);
    };

    const [state, showByState] = useState("all")
    return (
        <Grid container spacing={2}
            direction="column"
        >
            <Box sx={{ margin: 2, marginTop: 6 }}>
                <FormControl>
                    <InputLabel>State</InputLabel>
                    <Select
                        value={state}
                        label="State"
                        onChange={handleChange}
                    >
                        <MenuItem value={"all"}>All States</MenuItem>
                        <MenuItem value={"CA"}>California</MenuItem>
                    </Select>
                </FormControl>
            </Box>

            {entertainment.map(rec => (rec.state === state || state === "all") && <Grid item> <FunRec
                name={rec.name}
                address={rec.address}
                city={rec.city}
                state={rec.state}
                type={rec.type}
                description={rec.description}
                img={rec.img}
            />
            </Grid>

            )}
        </Grid>
    )
}

export default FunRecs;