const entertainment = [
    {
        name: "West Wind Capitol Drive In",
        address: "3630 Hillcap Ave",
        city: "San Jose",
        state: "CA",
        type: "",
        description: "You heard me right: an honest to god drive in movie theater.  When they aren't showing movies, they're hosting the flea market – there's even a liquor store and a Starbucks nearby.",
        img: "https://www.sanjose.org/sites/default/files/styles/listing_detail_image/public/2019-02/capitol-drive-in.jpg?h=89eaebb6&itok=Y7cbEgxl"
    }, 
    
]

export default entertainment;

/**
 * Template
 *   
 * {
        name: "",
        address: "",
        city: "",
        state: "",
        description: "",
        img: ""
    }, 
 */