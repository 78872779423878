import React from 'react';
import {Box, Grid, Typography, Paper} from '@mui/material';

const Home = props => {
    const paragraphStyle = {};
    const titleStyle = {};
return (
    <Paper>
        <Grid container direction="column"  >
        <Grid item>
           <Typography variant="h4">Steakdriven's Roadhouse</Typography>
        </Grid>
        <Grid item>
            <Typography variant="body1">
                Welcome to the roadhouse, fellow adventurers!  This portion of the website is under severe construction, and is somewhat scuffed, but that's because your host prioritized getting the restaurant recommendation section up and running in the lead up to magWest!
            </Typography>
        </Grid>
        <Grid item>
            <Typography variant="h4">
                    Who is Steakdriven?
            </Typography>
        </Grid>
        <Grid item>
            <Typography variant="body1" align="left">
                    Steakdriven, or <strong>Steak</strong> for short, is a Youtuber, a variety streamer, an amateur voice actor, a photographer, an adventuring gourmand,  an all-American man of culture, and a classic party animal.  Ever a seeker of adventure and excitement, he drives across the U.S.A. with the goal of finding the best restaurants, bars, and coffee shops wherever the road may lead.  But his favorite thing to do with what he finds on his adventures is to share them with others, to encourage his viewers to go out and enjoy the incredible sights, sounds, and tastes that this country has to offer! But what does he do when he gets home, covered as he is in road dust? Why, he plays video games like everyone else does, of course - and much like his real world adventures, he loves to share his digital experiences with others.  But perhaps the best way to describe Steakdriven is a man with an identity crisis - some days he wants to be a rough and tumble shot-swilling madlad, and other days he wants to be a waistcoat-wearing gentleman who sips wine and uses words like "jejune".  But all and all he just wants to entertain! 
            </Typography>
        </Grid>
        <Grid item>
            <Typography variant="h4">
                Background
            </Typography>
        </Grid>
        <Grid item>
                <Typography variant="body1" align="left"> Steak was born and bred in California - which of course means he peppers his lingo with words like "dude" and gobbles down avocado at an alarming rate.  He comes from a long line of adventurous souls - the most notable being renowned frontiersman Daniel Boone. </Typography>           
        </Grid>
       </Grid>
    </Paper>
    
)
}  

export default Home;