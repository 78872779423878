const coffee =[
     {
        name: "Blvd Coffee",
        address: "6055 Meridian Ave #20",
        city: "San Jose",
        state: "CA",
        description: "An ultra-comfortable setting with couches, far from the hustle and bustle of downtown.  Perhaps the only place I know that makes an Americano well. ",
        img: "https://s3-media0.fl.yelpcdn.com/bphoto/t2bMZjJ-xCXksl0qlVkSnw/l.jpg"
    }, 
    {
        name: "Mojo Coffee Co.",
        address: "1700 N Park Dr",
        city: "Winslow",
        state: "AZ",
        description: "Quaint, roadside coffee shop. I had the Rwandan blend when I first visited, on my way to Amarillo; it's right off the 40 in Winslow.",
        img: "https://s3-media0.fl.yelpcdn.com/bphoto/Zu7FrdCeGLoh7FPj5f6UEQ/348s.jpg"
    },
]

export default coffee;

/**
 * Template
 *   
 * {
        name: "",
        address: "",
        city: "",
        state: "",
        description: "",
        img: ""
    }, 
 */